<template xmlns="http://www.w3.org/1999/html">
  <q-card style="width: 1100px; max-width: 80vw;">
    <q-card-section class="q-gutter-md">
      <q-input
          filled
          dense
          autogrow
          v-model="taskData.name"
          style="font-size: 20px;"
      />

      <div class="row">
        <q-select filled
                  dense
                  v-model="taskStatus"
                  label="Статус"
                  :options="statusList"
                  :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                  :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                  style="width: 160px"/>
        <q-btn flat icon="chevron_right" @click="setSelectToValueTaskStatus(getStatusById(taskStatus.id).sort + 1)">След.</q-btn>

        <q-select filled
                  dense
                  v-model="taskAssignee"
                  label="Ответственный"
                  :options="userList"
                  :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                  :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                  style="width: 160px; margin-left: 30px;">
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar>
                <q-img
                    v-if="userList.filter(obj => { return obj.id === scope.opt.id })[0]?.avatar"
                    :src="userList.filter(obj => { return obj.id === scope.opt.id })[0]?.avatar"
                    spinner-color="white"
                    style="width: 24px; height: 24px; border-radius: 100%;"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ scope.opt.name }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <q-btn flat icon="close" @click="setSelectToValueTaskAssignee(null)"/>

        <q-input
            filled
            dense
            mask="##.##.####"
            v-model="taskDueDate"
            label="Дата"
            style="width: 160px; margin-left: 30px;"
        />
        <q-btn flat icon="event">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale" ref="qDateProxy">
            <q-date
                v-model="taskDueDate"
                mask="DD.MM.YYYY"
                minimal
                @update:model-value="$refs.qDateProxy.hide()"
                :options="dueOptionsFn"
            />
          </q-popup-proxy>
        </q-btn>

        <q-btn flat no-caps class="q-ml-xl">
          <div v-if="taskSettings.invoice_status">
            <q-icon name="currency_ruble"
                    :color="$settings.getInvoiceStatusColor(taskSettings.invoice_status).color"
                    size="xs"/>
            {{ $settings.getInvoiceStatusColor(taskSettings.invoice_status).name }}
          </div>
          <div v-else>
            <q-icon name="currency_ruble"
                    :color="grey-3"
                    size="xs"/>
            Счет
          </div>

          <q-menu>
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup @click="setTaskInvoiceStatusToOptions('invoiced')">
                <q-item-section avatar>
                  <q-icon name="currency_ruble" :color="$settings.getInvoiceStatusColor('invoiced').color" size="sm"/>
                </q-item-section>
                <q-item-section>{{ $settings.getInvoiceStatusColor('invoiced').name }}</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="setTaskInvoiceStatusToOptions('paid')">
                <q-item-section avatar>
                  <q-icon name="currency_ruble" :color="$settings.getInvoiceStatusColor('paid').color" size="sm"/>
                </q-item-section>
                <q-item-section>{{ $settings.getInvoiceStatusColor('paid').name }}</q-item-section>
              </q-item>
              <q-separator/>
              <q-item clickable v-close-popup @click="setTaskInvoiceStatusToOptions(false)">
                <q-item-section avatar>
                  <q-icon name="block" size="sm"/>
                </q-item-section>
                <q-item-section>Нет счета</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>

        <q-btn flat no-caps class="q-ml-xl">
          <div v-if="taskSettings.priority">
            <q-icon name="flag"
                    :color="$settings.getPriorityFlagColor(taskSettings.priority).color"
                    size="xs"/>
            {{ $settings.getPriorityFlagColor(taskSettings.priority).name }}
          </div>
          <div v-else>
            <q-icon name="o_flag"
                    :color="grey-3"
                    size="xs"/>
            Приоритет
          </div>

          <q-menu>
            <q-list style="min-width: 100px">
              <q-item clickable v-close-popup @click="setTaskPriorityToOptions('urgent')">
                <q-item-section avatar>
                  <q-icon name="flag" :color="$settings.getPriorityFlagColor('urgent').color" size="sm"/>
                </q-item-section>
                <q-item-section>{{ $settings.getPriorityFlagColor('urgent').name }}</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="setTaskPriorityToOptions('high')">
                <q-item-section avatar>
                  <q-icon name="flag" :color="$settings.getPriorityFlagColor('high').color" size="sm"/>
                </q-item-section>
                <q-item-section>{{ $settings.getPriorityFlagColor('high').name }}</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="setTaskPriorityToOptions('normal')">
                <q-item-section avatar>
                  <q-icon name="flag" :color="$settings.getPriorityFlagColor('normal').color" size="sm"/>
                </q-item-section>
                <q-item-section>{{ $settings.getPriorityFlagColor('normal').name }}</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="setTaskPriorityToOptions('low')">
                <q-item-section avatar>
                  <q-icon name="flag" :color="$settings.getPriorityFlagColor('low').color" size="sm"/>
                </q-item-section>
                <q-item-section>{{ $settings.getPriorityFlagColor('low').name }}</q-item-section>
              </q-item>
              <q-separator/>
              <q-item clickable v-close-popup @click="setTaskPriorityToOptions(false)">
                <q-item-section avatar>
                  <q-icon name="block" size="sm"/>
                </q-item-section>
                <q-item-section>Без приоритета</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>

    </q-card-section>

    <q-card-section class="q-gutter-md">
      <ckeditor :editor="editor"
                v-model="taskDescription"
                :config="editorConfig"
      ></ckeditor>
    </q-card-section>


    <q-card-section class="q-gutter-md">
      <div v-if="this.attachments.length">
        <p><b>Файлы</b></p>
        <span v-for="attachment in attachments" style="display: inline; margin: 10px;">
          <q-dialog
              full-width
              v-if="isFileAnImage(attachment.path)"
              v-model="dialogModel">
            <q-img
                :src="`https://app-dev.vponedelnik.ru/${dialogPath}`"
            />
          </q-dialog>
<!--          `https://app-dev.vponedelnik.ru/${attachment.path}`-->
          <a
              @click.stop="dialogModel = true; dialogPath = attachment.path"
              v-if="isFileAnImage(attachment.path)"
              href="#">
            <q-img
                :src="`https://app-dev.vponedelnik.ru/${attachment.path}`"
                style="height: 100px; max-width: 200px"
            >
              <div class="absolute-bottom text-subtitle3 text-center">
                {{attachment.name}}
              </div>
            </q-img>
          </a>
          <a v-else
             href="#"
             @click="axiosGetFile('/attachment/' + attachment.id, attachment.name)"
             style="border: 1px solid #ddd; border-radius: 4px; padding: 8px 12px;">
                {{attachment.name}}
          </a>
        </span>
      </div>
      <div class="row">
        <div style="width: 100%;">
          <q-uploader
              ref="xuploader"
              label="Этой строки, наверное, не будет"
              @added="uploadFileToTask"
              multiple
              flat
              style="width: 100%; height: 55px; border: 2px dashed #ccc"
          >
            <template v-slot:header></template>
            <template v-slot:list>
              <q-icon name="attach_file"/>
              Перетащите файлы сюда или
              <q-btn dense no-caps @click="(event) => $refs.xuploader.pickFiles()" label="Прикрепите" class="q-ml-md"/>
              <q-uploader-add-trigger></q-uploader-add-trigger>
            </template>
          </q-uploader>
        </div>
      </div>
    </q-card-section>

    <q-card-section class="q-gutter-md">
      <div v-if="comments.length" class="comments">
        <b>комментарии</b>
        <div class="item" v-for="comment in comments" style=" border: 1px solid #eee; margin: 10px 0; padding: 8px 10px; border-radius: 4px">
          <div style="opacity: 0.5; font-size: 12px;">{{comment.user.name}}, {{$dayjs(comment.created_at).format('LLL')}}</div>
          {{ comment.comment }}
        </div>
      </div>

      <br/>

      <b>новый комментарий</b>
      <q-input
          filled
          dense
          autogrow
          v-model="newComment"
      />
      <q-btn flat label="Комментировать" @click="createComment" />
    </q-card-section>

    <q-card-section class="q-gutter-md">

      <b>Время задачи ({{calcWorksDuration()}})</b>

      <q-table
          v-if="user && [1,3,9].includes(user.id)"
          dense
          :is-loading="isLoading"
          :rows="workList || []"
          :columns="columns"
          :pagination="{ page: 1, rowsPerPage: 0 }"
          hide-bottom
          row-key="name"
          :visible-columns="user && [1,3].includes(user.id) ? visibleColumnsSuperadmin : (user && [9].includes(user.id) ? visibleColumnsUser : visibleColumnsClient)"
      >
        <template v-slot:body-cell-name="props">
          <q-td :props="props"
                :label="props.value"
                :class="'wrap-me'"
                v-html="props.value">
          </q-td>
        </template>

        <template v-slot:body-cell-task="props">
          <q-td :props="props"
                :label="props.value"
                :class="'wrap-me'">
            {{props.value}}
          </q-td>
        </template>

        <template v-slot:body-cell-action="props">
          <td align="right">
            <q-btn-dropdown dense flat color="grey" dropdown-icon="more_horiz" @click.stop="">
              <q-list>
                <q-item clickable v-close-popup @click.stop="editWork(props.row.id)">
                  <q-item-section avatar>
                    <q-icon name="edit"></q-icon>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Редактировать</q-item-label>
                  </q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup @click.stop="deleteWork(props.row.id)">
                  <q-item-section avatar>
                    <q-icon name="delete" color="red"></q-icon>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Удалить</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </td>
        </template>
      </q-table>

      <q-btn
          v-if="user && [1,3,9].includes(user.id)"
          label="Добавить работу"
          @click.stop="openWorkAddModal" />
    </q-card-section>

<!--    <q-card-section class="q-gutter-md">-->
<!--      {{ worksList }}-->
<!--    </q-card-section>-->

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="удалить" v-if="user.settings.allow?.task?.delete" @click="deleteTask" />
      <q-btn flat label="закрыть" v-close-popup />
      <q-btn flat label="сохранить" @click="updateTask" />
    </q-card-actions>
  </q-card>
</template>

<script>
import {task} from "@/api/task";
import {comment} from "@/api/comment";
import {attachment} from "@/api/attachment";
import { ref } from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapGetters, mapMutations } from 'vuex'
import axios from "@/helpers/axios";
import { notification } from '@/api/notification'
import { work } from '@/api/work'
import { duration } from '@/helpers/duration'
import WorkAddModal from '@/components/works/WorkAddModal'
import CkUploadAdapter from '@/helpers/ckuploadadapter'

export default {
  name: "TaskSingle",
  components: {
    ckeditor: CKEditor.component
  },
  emits: ["task-updated", "notifications-mark-read"],
  props: {
    task: null,
    userList: null,
    projectList: null,
    statusList: null,
  },
  computed: {
    ...mapGetters({
      user: "account/user",
      tasks: "account/tasks",
    }),
  },
  setup() {
    return {
      dueDate: ref('DD.MM.YYYY'),
      token: JSON.parse(localStorage.getItem("minutka-app"))?.token,


      visibleColumnsSuperadmin: [ 'id', 'name', 'duration', 'duration_c', 'timeStart', 'user', 'invoice_id', 'action' ],
      visibleColumnsUser:       [ 'id', 'name', 'duration',               'timeStart', 'user',               'action' ],
      visibleColumnsClient:     [ 'id', 'name', 'duration',               'timeStart',                       'action' ],
    }
  },
  data() {
    return {
      taskData: null,
      taskName: null,
      // userId: 1,,
      taskAssignee: null,
      taskProject: null,
      taskStatus: null,
      taskDueDate: null,
      taskDescription: null,
      taskSettings: null,

      worksList: null,

      comments: null,
      newComment: null,
      attachments: null,

      dialogModel: ref(false),
      dialogPath: ref(false),

      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          // uploadUrl: 'https://api-dev.vponedelnik.ru/attachment/create/',

          extraPlugins: [ this.CkCustomUploadAdapterPlugin ],
          // headers: {'Authorization': `Bearer ${JSON.parse(localStorage.getItem("minutka-app"))?.token}`}
        },
      },

      // temp
      selected_file: '',

      //
      input: '',
      date: '2018/11/03',

      workList: [],

      columns: [
        {name: 'id', label: 'id', align: 'left', field: 'id',
          sortable: true},
        {name: 'name', required: true, label: 'Описание', align: 'left',
          field: row => row.description ? row.description : row.name,
          format: val => `${val}`,
        },
        {name: 'duration', label: 'Время', align: 'left', field: 'durationStr'},
        {name: 'duration_c', label: 'ВремяЧ', align: 'left', field: 'durationStrC'},
        {name: 'timeStart', label: 'Дата', align: 'left', field: 'started_at',
          sortable: true,
          sortOrder: 'ad',
          raw: (a, b, rowA, rowB) => this.$dayjs(a).valueOf() - this.$dayjs(b).valueOf(),
          rawSort: (a, b, rowA, rowB) => this.$dayjs(a).valueOf() - this.$dayjs(b).valueOf(),
          format: val => {
            return val.replace(' 00:00:00', '');  // todo: сделать нормальный формат даты
          },
        },
        {name: 'user', label: 'User', align: 'left', field: 'user_name'},
        {name: 'invoice_id', label: 'Invoice', align: 'left', field: 'invoice_id'},
        {name: "action", label: null, align: "right"},
      ]
    }
  },
  created() {
    this.taskData = this.task;
    this.taskName = this.taskData.name;
    this.taskDueDate = this.taskData.due_date ? this.$dayjs(this.taskData.due_date).format("DD.MM.YYYY") : null;
    this.comments = this.taskData.comments;
    this.attachments = this.taskData.attachments;
    this.taskDescription = this.taskData.description;
    this.taskSettings = this.taskData.settings ? JSON.parse(this.taskData.settings) : {};
    this.markReadByTask();
    this.listWorks();
  },
  mounted() {
    this.taskAssignee = this.userList.filter(obj => {
      return obj.id === this.taskData.assignee;
    })[0];
    this.taskProject = this.projectList.filter(obj => {
      return obj.id === this.taskData.project_id;
    })[0];
    this.taskStatus = this.statusList.filter(obj => {
      return obj.id === this.taskData.group_id;
    })[0];
  },
  methods: {
    CkCustomUploadAdapterPlugin (editor) {
      console.log('=======')
      console.log(editor)
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        // Configure the URL to the upload script in your back-end here!
        return CkUploadAdapter(loader)
      }
    },
    async markReadByTask() {
      if (this.taskData.notifications.length > 0) {
        try {
          const result = await notification.markReadByTask(this.taskData.id);
        } catch (error) {
          if (error.response?.status !== 404) {}
        } finally {
          // todo: это надо делать где-то снаружи и только если были уведомления а не каждый раз тут
          this.$emit("notifications-mark-read");
        }
      }
    },
    async updateTask() {
      try {
        let options = {
          id: this.taskData.id,
          name: this.taskData.name,
          estimate_minutes: this.taskData.estimate_minutes,
          project_id: this.taskData.project_id,
          description: this.taskDescription,
          group_id: this.taskStatus.id,
          settings: JSON.stringify(this.taskSettings),
        }
        options.due_date = this.taskDueDate ? this.$dayjs(this.taskDueDate, "DD.MM.YYYY").format("L") : null;
        options.assignee = this.taskAssignee ? this.taskAssignee.id : -1;
        const result = await task.update(options);
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.$emit("task-updated");
      }
    },
    async deleteTask() {
      try {
        const result = await task.delete({
          id: this.taskData.id,
        });
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.$emit("task-updated");
      }
    },
    async createComment() {
      try {
        const result = await comment.create({
          type: "task",
          id: this.taskData.id,
          comment: this.newComment,
          user_id: this.user.id,
        });
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.updateComments();
        this.newComment = null;
      }
    },
    async updateComments () {
      try {
        const result = await task.getOne({
          id: this.taskData.id,
        });

        this.comments = result.comments;
      } catch (error) {
        if (error.response.status !== 404) {
        }
      } finally {}
    },
    async updateAttachments () {
      try {
        const result = await task.getOne({
          id: this.taskData.id,
        });

        this.attachments = result.attachments;
      } catch (error) {
        if (error.response.status !== 404) {
        }
      } finally {}
    },
    async uploadFileToTask(files) {
      try {
        const result = await attachment.create({
          type: "task",
          id: this.taskData.id,
          file: files[0],
          user_id: this.user.id,
        });
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.updateAttachments();
      }
    },

    dueOptionsFn (date) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;

      return date >= today
      // return date >= '2023/09/16'
    },

    setSelectToValueTaskStatus (val) {
      this.taskStatus = this.statusList.filter(obj => {
        return obj.sort === val;
      })[0];
    },
    setSelectToValueTaskAssignee (val) {
      if(val) {
        this.taskAssignee = this.userList.filter(obj => {
          return obj.sort === val;
        })[0];
      } else {
        this.taskAssignee = null;
      }
    },

    getStatusById(id) {
       return this.statusList.filter(obj => {
        return obj.id === id;
      })[0];
    },
    ...mapMutations({
    }),

    axiosGetFile(url, filename) {
      axios
        .get(url, {
            responseType: 'blob', // important
        })
        .then(fileResult => {
          const url = window.URL.createObjectURL(new Blob([fileResult.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      return;
    },

    setTaskPriorityToOptions(priority) {
      if (priority === false) {
        delete this.taskSettings.priority;
      }
      else if (priority !== false) {
        this.taskSettings.priority = priority;
      }
    },

    setTaskInvoiceStatusToOptions(status) {
      if (status === false) {
        delete this.taskSettings.invoice_status;
      }
      else if (status !== false) {
        this.taskSettings.invoice_status = status;
      }
    },

    isFileAnImage(filename) {
      // console.log(filename)
      // console.log(filename.lastIndexOf('.'))
      // console.log(filename.slice(filename.lastIndexOf('.')))
      let extensions = ['jpg', 'png', 'svg', 'jpeg', 'webp', 'gif'];
      if (extensions.includes(filename.slice(filename.lastIndexOf('.')+1))) {
        return true;
      }
      return false;
    },

    async listWorks() {
      try {
        this.isLoading = true;

        const options = {};

        options.task_id = this.taskData.id;
        // options.user_id = this.user.id;

        const result = await work.list(options);

        // TODO: вынести из загрузки
        result.forEach((el) => {
          el.durationStr  = duration.minutesToString(el.duration_minutes);
          el.durationStrC = duration.minutesToString(el.client_duration_minutes);
          // el.project_name = el.project.name; //this.getProjectNameById(el.project_id);
          // el.task_name    = el.task.name; //this.getTaskNameById(el.task_id);
          el.user_name    = el.user.name; //this.getUserNameById(el.user_id);
        });

        this.workList = result
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deleteWork(id) {
      try {
        const result = await work.delete({
          id: id,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.listWorks();
      }
    },

    calcWorksDuration() {
      let worksDuration = 0;

      this.workList.forEach(function (el) {
        worksDuration += el.client_duration_minutes;
      });

      return (worksDuration / 60).toFixed(2);
      //(this.taskData.works_client_duration_minutes/60).toFixed(2)
    },


    openWorkAddModal() {
      return this.$q
        .dialog({
          component: WorkAddModal,
          componentProps: {
            taskId: this.taskData.id,
            projectId: this.taskData.group.project_id,
          },
        })
        .onOk((comment) => {
          //this.$emit('refresh-comments', comment, hash)
          this.listWorks();
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap-me {
  white-space: normal;
}
:deep .wrap-me p:last-child {
  margin-bottom: 0 !important;
}
</style>
