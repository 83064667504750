<template>
  <div class="q-pa-md">
    <div class="page-title-row">
      <div class="row">
        <router-link :to="'/projects/'+$route.params.project_id+'/doc'">
          <q-icon
              name="arrow_back"
              size="md"
          />
        </router-link>
<!--        <h6 style="margin: 0 0 0 20px">{{ doc ? doc.name : 'Без названия' }}</h6>-->

        <q-input
            filled
            dense
            autogrow
            v-model="docName"
            style="font-size: 20px;"
        />

        <q-btn size="md"
               padding="xs md"
               label="Сохранить"
               icon="o_save"
               @click="saveDocument"
               style="margin-left: auto"/>
      </div>

      <div class="q-my-lg">
        <div v-if="isLoading" class="loading">Грузим...</div>

        <div v-if="error" class="error">{{ error }}</div>

        <div v-if="doc" class="content">
          <ckeditor :editor="editor"
                    v-model="docBody"
                    :config="editorConfig"
          ></ckeditor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { document } from '@/api/document'
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'Document',
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      isLoading: false,
      error: null,

      docName: null,
      docBody: null,

      doc: null,

      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: 'https://api-dev.vponedelnik.ru/attachment/create/',
          headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem("minutka-app"))?.token}`}
        },
      },
    }
  },
  created () {
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchDocument()
        },
        { immediate: true }
    )
  },
  methods: {
    async fetchDocument(options) {
      this.error = this.post = null
      this.isLoading = true

      try {
        options = options ?? {};
        options.id = this.$route.params.id;

        const result = await document.getOne(options);

        this.isLoading = false

        this.doc = result;

        this.docName = this.doc.name;
        this.docBody = this.doc.body;
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {
        this.isloading = false
      }
    },
    async saveDocument(options) {
      this.error = this.post = null
      this.isLoading = true

      try {
        options = options ?? {};
        options.id = this.$route.params.id;
        options.name = this.docName;
        options.body = this.docBody;

        // update или create в зависимости от того новый ли док
        if (this.$route.params.id) {
          const result = await document.update(options);

          if(result.id) {
            this.$router.push({
              name: 'docSingle',
              params: {
                project_id: this.$route.params.project_id,
                id: result.id,
              },
            })
          }
        } else {
          console.log('CREATION NEEDED')
          // const result = await document.create();
        }
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {
        this.isloading = false
      }
    },

  },
}
</script>

<style scoped>

</style>
